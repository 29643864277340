// @flow

import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import Button from '../../basic/Button'

import messages from './ITBBookingAppointment.lang'
import baseStyles from './ITBBookingAppointment.scss'

type Props = {
  ...StyleProps
}

const ITBBookingAppointment = ({ styles }: Props) => {
  const { formatMessage, locale } = useIntl()
  const currentDate = new Date()
  const cutoffDate = new Date("2025-03-06T17:00:00")
  const link = `https://start.freshcells.de/${locale}/lets-talk-at-itb`

  return (
    currentDate < cutoffDate ?
    <div className={styles.root}>
      <div className={styles.text}><strong>{formatMessage(messages.text)}</strong></div>
      <a href={link} target="_blank" rel="noopener noreferrer" className={styles.btnLink}>
        <Button styles={styles.btn} tag="span" color="primary" iconRight="icon-arrow_03-right-medium">
          {formatMessage(messages.btnLabel)}
        </Button>
      </a>
    </div> : null
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(ITBBookingAppointment)
