import React from 'react'
import { useIntl, Link } from 'gatsby-plugin-intl'
import Layout from '../../components/Layout/index'
import Header from '../../modules/Header'
import Footer from '../../modules/Footer'
import SEO from '../../components/seo'
import PressRelease from '../../components/PressRelease'
import ITBBookingAppointment from '../../components/PressRelease/ITBBookingAppointment'

import messages from '../../messages/pageTitles.lang'
import metaDescription from '../../messages/pageMetaDescriptions.lang'
import Image from '../../images/news/schauinsland-reisen_1_1_en.jpg'
import ImageDE from '../../images/news/schauinsland-reisen_1_1_de.jpg'

import { newsList } from '../../components/NewsPage/data'

const IndexPage = () => {
  const { formatMessage, locale } = useIntl()

  return (
    <Layout footer={<Footer />} header={<Header withTeaser />}>
      <SEO
        title={formatMessage(messages.TitleSchauinsland)}
        imageAlt={formatMessage(messages.TitleSchauinsland)}
        description={formatMessage(metaDescription.NewsSchauinslandDescription)}
        image={locale === 'de' ? ImageDE : Image}
        lang={locale}
      />
      <PressRelease
        article={newsList.schauinsland}
        articleLinks={{
          tsbo: (
            <Link to="/products/quickstart/">
              TravelSandbox®
            </Link>
          )
        }}
        addon={<ITBBookingAppointment />}
      />
    </Layout>
  )
}

export default IndexPage
